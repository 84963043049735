<template>
  <span>
    <a-button :disabled="disabled" type="primary" @click="visible = true">添加项目</a-button>

    <a-modal title="选择项目" :visible="visible" @cancel="cancel" :footer="null" width="800px">
      <a-input-search placeholder="请输入关键词搜索" enter-button @search="onSearch"></a-input-search>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        :rowClassName="() => 'clickable'"
        :customRow="
          (record) => {
            return {
              on: {
                click: () => {
                  onTableRowClick(record);
                },
              },
            };
          }
        "
        rowKey="id"
      >
        <a-table-column title="设计编号" data-index="designCode" />
        <a-table-column title="项目名称" data-index="name" />

        <a-table-column key="progress" title="累计项目进度" align="center">
          <template slot-scope="text">
            <div v-if="typeof text.progress === 'number'" class="between">
              <a-progress :percent="typeof  text.monthlyProgress === 'number' ? text.monthlyProgress : text.progress" :showInfo="false" />
              <span style="color: rgba(0, 0, 0, 0.45); width: 50px">{{ typeof  text.monthlyProgress === 'number' ? text.monthlyProgress : text.progress }}%</span>
            </div>
            <div v-else></div>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </span>
</template>

<script>
import request from "@/api/request";

function fetchList(data) {
  return request({
    url: "/project-service/project/sonList",
    method: "post",
    data
  });
}

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,

      searchValue: "",

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0
    };
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        if (this.list.length === 0) {
          this.getList();
        }
      }
    }
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        name: this.searchValue
      })
        .then(res => {
          if (res && Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    onSearch(value) {
      this.searchValue = value;
      this.current = 1;
      this.getList();
    },

    onTableRowClick(record) {
      this.$emit("change", record);
      this.visible = false;
    },

    cancel() {
      this.visible = false;
    }
  }
};
</script>