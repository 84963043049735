var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('a-button',{attrs:{"disabled":_vm.disabled,"type":"primary"},on:{"click":function($event){_vm.visible = true}}},[_vm._v("添加项目")]),_c('a-modal',{attrs:{"title":"选择项目","visible":_vm.visible,"footer":null,"width":"800px"},on:{"cancel":_vm.cancel}},[_c('a-input-search',{attrs:{"placeholder":"请输入关键词搜索","enter-button":""},on:{"search":_vm.onSearch}}),_c('Padding'),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      },"rowClassName":() => 'clickable',"customRow":(record) => {
          return {
            on: {
              click: () => {
                _vm.onTableRowClick(record);
              },
            },
          };
        },"rowKey":"id"},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"设计编号","data-index":"designCode"}}),_c('a-table-column',{attrs:{"title":"项目名称","data-index":"name"}}),_c('a-table-column',{key:"progress",attrs:{"title":"累计项目进度","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [(typeof text.progress === 'number')?_c('div',{staticClass:"between"},[_c('a-progress',{attrs:{"percent":typeof  text.monthlyProgress === 'number' ? text.monthlyProgress : text.progress,"showInfo":false}}),_c('span',{staticStyle:{"color":"rgba(0, 0, 0, 0.45)","width":"50px"}},[_vm._v(_vm._s(typeof text.monthlyProgress === 'number' ? text.monthlyProgress : text.progress)+"%")])],1):_c('div')]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }